import React from "react"
import HelmetHead from "./HelmetHead";
import "bootstrap/dist/css/bootstrap.min.css";
import "./Layout.scss"

export default function Layout({ children }) {
  return (
    <>
      <HelmetHead />
      <a className="skip-link sr-only" href="#main-content">Skip to main content</a>
      {children}
    </>
  );
}
