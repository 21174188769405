import React from "react"
import { Container, Nav, Navbar } from "react-bootstrap";
import { smoothScrollToTop, handleSmoothScrollForHashHref } from "../utils/anchor-helpers";
import { Link } from "gatsby";

const navLinks = {
  'games': 'Games',
  'news': 'News',
  'about': 'About',
  'contact': 'Contact',
};

export default function Header({ anchorLinks = false }) {
  return (
    <header>
      <Navbar id="top-navbar" bg="dark" variant="dark" expand="md" fixed="top" collapseOnSelect>
        <Container fluid>
          {
            anchorLinks
              ? <Link id="navbar-logo" to="/" className="navbar-brand" onClick={(e) => smoothScrollToTop(e)}>Great Intrigue</Link>
              : <Link id="navbar-logo" to="/" className="navbar-brand">Great Intrigue</Link>
          }
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto" activeKey={null}>
              {
                anchorLinks
                  ? Object.entries(navLinks).map(
                    ([id, label]) => (
                      <Nav.Link key={id} href={`#${id}`} className="nav-link" onClick={(e) => handleSmoothScrollForHashHref(e)}>{label}</Nav.Link>
                    )
                  )
                  : Object.entries(navLinks).map(
                    ([id, label]) => (
                      <Link key={id} to={`/#${id}`} className="nav-link">{label}</Link>
                    )
                  )
              }
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}
