import React from "react"
import Helmet from 'react-helmet';

export default function HelmetHead() {
  const defaultPageTitle = 'Great Intrigue';
  const metaDescription = 'Great Intrigue LLC - Indie game developers and creators of the game Lava Llama.';
  const metaKeywords = 'great intrigue, lava llama, indie games, mobile games, platformer, iphone, ipad, ios, android, games';
  const metaAuthor = 'Great Intrigue LLC';
  const metaImageUrl = 'https://www.greatintrigue.com/share-images/great-intrigue-share-logo.png';

  return (
    <Helmet htmlAttributes={{ lang: 'en' }}>
      <title>{defaultPageTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={metaKeywords} />
      <meta name="author" content={metaAuthor} />

      {/* Favicon and icon */}
      <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
      <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
      <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
      <link rel="manifest" href="/site.webmanifest" />
      <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#000048" />
      <meta name="msapplication-TileColor" content="#000048" />
      <meta name="apple-mobile-web-app-title" content="GreatIntrigue.com" />
      <meta name="application-name" content="GreatIntrigue.com" />
      <meta name="theme-color" content="#ffffff" />

      {/* OpenGraph Metadata for Facebook */}
      <meta property="og:url" content="https://greatintrigue.com/" />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:title" content={defaultPageTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={metaImageUrl} />
    </Helmet>
  );
}
