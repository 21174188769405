export const smoothScrollToTop = (e) => {
  e.preventDefault();
  window.scrollTo({ top: 0, behavior: 'smooth' });

  // Remove hash portion of url, if present
  window.history.pushState("", document.title, window.location.pathname + window.location.search);

  // Shortly after the click, the link should lose focus
  setTimeout(() => e.target.blur(), 500);
}

export const handleSmoothScrollForHashHref = (e) => {
  e.preventDefault();
  const targetId = e.target.getAttribute('href').replace(/^#/, '');
  const targetElement = document.getElementById(targetId);
  window.history.pushState(null, null, `#${targetId}`);
  targetElement.scrollIntoView({ behavior: 'smooth' });

  setTimeout(() => {
    // Shortly after the click, the link should lose focus
    e.target.blur();
    // And the target element should gain focus
    targetElement.focus();
  }, 500);
}
